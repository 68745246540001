import React from "react"
import loadable from "@loadable/component"

const Jumbotron = loadable(() => import("./components/jumbotron"))
const MasonryGrid = loadable(() => import("./components/masonry-grid"))
const Callout = loadable(() => import("./components/callout"))
const SupportCallouts = loadable(() => import("./components/support-callouts"))

const EmbarkAndLead = () => (
  <section>
    <Jumbotron />
    <MasonryGrid />
    <Callout />
    <SupportCallouts />
  </section>
)

export default EmbarkAndLead
